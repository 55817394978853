import TypeIt from "typeit";

export default class typeEffect {
  constructor() {
    this.init();
  }
  init() {
    let typeTxt = document.querySelectorAll('.js-typewriter');

    window.onload = function() {
      for (let i = 0; i < typeTxt.length; i++) {
        if(typeTxt[i].dataset.speed) {
          new TypeIt(typeTxt[i], {
            speed: typeTxt[i].dataset.speed,
            startDelay: 1250,
            afterComplete: function (instance) {
              instance.destroy();
            }
          }).go();
        } else {
          new TypeIt(typeTxt[i], {
            speed: 15,
            startDelay: 1250,
            afterComplete: function (instance) {
              instance.destroy();
            }
          }).go();
        }
      }
    }
  }
}