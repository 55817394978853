export default class switchBtn {
  constructor() {
    this.init();
  }
  init() {
    const btnPc = document.querySelector("#modeChangePC");
    const btnSp = document.querySelector("#modeChangeSP");
    
    if(btnPc){
      btnPc.addEventListener("change", () => {
        if (btnPc.checked == true) {
          document.body.classList.add("l-darkTheme");
          btnSp.checked = true;
          localStorage.setItem('black', btnPc);
        } else {
          document.body.classList.remove("l-darkTheme");
          btnSp.checked = false;
          localStorage.removeItem('black')
        }
      });
    }

    if(btnSp){
      btnSp.addEventListener("change", () => {
        if (btnSp.checked == true) {
          document.body.classList.add("l-darkTheme");
          btnPc.checked = true;
          localStorage.setItem('black', btnSp);
        } else {
          document.body.classList.remove("l-darkTheme");
          btnPc.checked = false;
          localStorage.removeItem('black')
        }
      });
    }
  }
}