export default class modalCart {
  constructor() {
    this.init();
  }
  init() {
    let pop = document.querySelectorAll('.js-modalCart');
    let pos;

    for (let i = 0; i < pop.length; i++) {
      let box = pop[i];
      let btn = box.querySelector('.js-modalCartBtn');

      if(btn) {
        btn.addEventListener('click', function (event) {
          pos = $(window).scrollTop();
          $('.p-modalCart').removeClass('is-hide');
          $('body').addClass('is-fixed').css({'top': -pos});
        });
      }
    }

    let close = document.querySelector('.js-modalCartColseBtn');
    let bg = document.querySelector('.p-modalCart__bg');

    if(close) {
      close.addEventListener('click', () => {
        $('.p-modalCart').addClass('is-hide');
        $('body').removeClass('is-fixed').css({'top': 0});
        window.scrollTo(0, pos);
      })

      bg.addEventListener('click', () => {
        pos = $(window).scrollTop();
        $('.p-modalCart').addClass('is-hide');
        $('body').removeClass('is-fixed').css({'top': 0});
        window.scrollTo(0, pos);
      })
    }
  }
}