export default class category {
  constructor() {
    this.init();
  }
  init() {
    var searchItem = '.p-contents__navListItem';
    var listItem = '.p-productList__item';
    var hideClass = 'is-hide';
    var activeClass = 'is-active';
    
    $(function() {
      // 絞り込みを変更した時
      $(searchItem).on('click', function() {
        $(searchItem).removeClass(activeClass);
        var group = $(this).addClass(activeClass).data('group');
        search_filter(group);
      });
    });
    
    function search_filter(group) {
      // 非表示状態を解除
      $(listItem).removeClass(hideClass);
      // 値が空の場合はすべて表示
      if(group === '') {
        return;
      }
      // リスト内の各アイテムをチェック
      for (var i = 0; i < $(listItem).length; i++) {
        // アイテムに設定している項目を取得
        var itemData = $(listItem).eq(i).data('group');
        // 絞り込み対象かどうかを調べる
        if(itemData !== group) {
          $(listItem).eq(i).addClass(hideClass);
        }
      }
    }
  }
}