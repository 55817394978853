export default class parallax {
  constructor() {
    this.init();
  }
  init() {
    const elem = document.querySelector(".js-target-parallax");
    if (elem !== null) {
      let target = document.getElementsByClassName("js-target-parallax");
      let parallaxConfig = {
        delay: 0,
        orientation: "up",
        scale: 1.15
      };
  
      const parallax_instance = new simpleParallax(target, parallaxConfig);
  
  
      const breakpoint_P = window.matchMedia("(min-width: 768px)"); //PC用のブレイクポイント
      const breakpoint_S = window.matchMedia("(max-width: 767px)"); //スマホ用のブレイクポイント
      
      //サイトが表示されたときに１番最初に行われる設定
      function checkBreakPoint(breakpoint_P_get, breakpoint_S_get) {
        for (let i = 0; i < target.length; i++) {
          let target_src = target[i].getAttribute("src");
  
          if (breakpoint_P_get.matches) {
            let replace_src = target_src.replace("_sp", "_pc").replace("_tb", "_pc");
            target[i].setAttribute("src", replace_src);
          } else if (breakpoint_S_get.matches) {
            let replace_src = target_src.replace("_pc", "_sp").replace("_tb", "_sp");
            target[i].setAttribute("src", replace_src);
          }
        }
        parallax_instance.refresh();
      }
      
      //画面サイズが961px以上になると行われる設定
      function checkBreakPoint_pc(breakpoint_P) {
        for (let i = 0; i < target.length; i++) {
          let target_src = target[i].getAttribute("src");
  
          if (breakpoint_P.matches) {
            let replace_src = target_src.replace("_sp", "_pc").replace("_tb", "_pc");
            target[i].setAttribute("src", replace_src);
          }
        }
        parallax_instance.refresh();
      }
      
      //画面サイズが560px以下になると行われる設定
      function checkBreakPoint_sp(breakpoint_S) {
        for (let i = 0; i < target.length; i++) {
          let target_src = target[i].getAttribute("src");
  
          if (breakpoint_S.matches) {
            let replace_src = target_src.replace("_pc", "_sp").replace("_tb", "_sp");
            target[i].setAttribute("src", replace_src);
          }
        }
        parallax_instance.refresh();
      }
  
      // ブレイクポイントごとにそれぞれの設定が発火する
      breakpoint_P.addEventListener("change", checkBreakPoint_pc);
      breakpoint_S.addEventListener("change", checkBreakPoint_sp);
  
      // 初回チェックで発火
      checkBreakPoint(breakpoint_P, breakpoint_S);
    }
  }
}