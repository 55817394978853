import Swiper, { EffectFade, Thumbs } from 'swiper';

export default class MainSlide {
  constructor() {
    this.elm = document.querySelector('.js-mainSwiper');
    this.elmBar = document.querySelector('.js-switchListProgressItem');
    this.on()
  }
  staticSlide() {
    const elmbar = document.querySelector('.p-front__progress');
    const menu = document.querySelector('.p-front__menuItem');
    menu.classList.add('is-opacity')
    elmbar.classList.add('is-none')
  }
  init() {
    let isPause;
    let tick;
    let percentTime;
    let time = 1;

    const resetProgressbar = () => {
      this.elmBar.style.width = `0%`;
      clearTimeout(tick);
    };

    const interval = () => {
      if (isPause === false) {
        percentTime += 1 / (time + 0.1);
        this.elmBar.style.width = `${percentTime}%`;
        if (percentTime >= 100) {
          switchList.slideNext();
          startProgressbar();
        }
      }
    };

    const startProgressbar = () => {
      resetProgressbar();
      percentTime = 0;
      isPause = false;
      tick = setInterval(interval, 50);
    };
    startProgressbar();

    const thumList = new Swiper('.js-thumSwiper', {
      modules: [Thumbs],
      freemode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
      on: {
        slideChange: function () {
          startProgressbar();
        },
      },
    })

    const swipeAction = (num) => {
      let active = num + 1;
      let element = document.querySelector('.js-slideTxtBox');
      element.dataset.current = active;
    };

    const switchList = new Swiper('.js-mainSwiper', {
      modules: [EffectFade, Thumbs],
      loop: true,
      speed: 600,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 1200,
      },
      thumbs: {
        swiper: thumList
      },
      on: {
        slideChange: function (swiper) {
          startProgressbar();
          swipeAction(swiper.realIndex);
        },
      },
    })
  }
  on() {
    if (this.elm) {
      if ( $(".js-mainSwiper .swiper-slide").length == 1 ) { 
        this.staticSlide();
      } else {
        this.init();
      }
    }
  }
}

