"use strict";
import './structure/adjustVh';
import './structure/adjustViewport';
import './structure/TweenMax';
import './structure/jquery.inview.min';
import DarkMode from './components/darkMode';
import MainSlide from './components/mainSlide';
import Pass from './components/pass';
import Gnav from './components/gnav';
import Textcut from './components/textcut';
import Category from './components/category';
import MainDtl from './components/mainDtl';
import ProductDtl from './components/productDtl';
import Quantity from './components/quantity';
// import Cursor from './components/cursor';
import Pagelink from './components/pagelink';
import Parallax from './components/parallax';
import ProductList from './components/productList';
import TypeEffect from './components/typeEffect.js';
import SmoothScroll from './components/smoothScroll';
import BtnCheck from './components/btnCheck';
import ModalCart from './components/modalCart';
import ModalSite from './components/modalSite';
import Tax from './components/tax';
import ScrollBtn from './components/scrollBtn';
// import Textarea from './components/textarea';
import Load from './components/load';
import Picturefill from 'picturefill';
import ObjectFitImages from 'object-fit-images';

(() => {
  const btnPc = document.querySelector("#modeChangePC");
  const btnSp = document.querySelector("#modeChangeSP");

  if(btnPc && btnSp){
    if (localStorage.getItem('black')) {
      document.body.classList.add("l-darkTheme");
      btnPc.checked = true;
      btnSp.checked = true;
    } else {
      document.body.classList.remove("l-darkTheme");
      btnPc.checked = false;
      btnSp.checked = false;
    }
  }
})();

// inview
$(function () {
  $(".js-fadeUp").on("inview", function () {
    $(this).addClass("is-inview");
  });
});

// Object.assign Polyfill
if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function(target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }
        nextSource = Object(nextSource);

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
}

// ObjectFitImages
ObjectFitImages();

// picturefill
Picturefill();

document.addEventListener("DOMContentLoaded", () => {
  // ローダー
  const load = new Load();

  //  モード変換
  const darkMode = new DarkMode();

  //  メインスライド
  const mainSlide = new MainSlide();

  //  パスワード展開
  const pass = new Pass();

  //  グローバルナビゲーション
  const gnav = new Gnav();

  //  カテゴリー
  const category = new Category();

  //  フェード
  const mainDtl = new MainDtl();

  //  カーソル
  // const cursor = new Cursor();

  //  プロダクト
  const productDtl = new ProductDtl();

  // 文字数制限
  const textcut = new Textcut();

  // ページ内リンク
  const pagelink = new Pagelink();

  // パララックス
  const parallax = new Parallax();

  // スクロールで出す
  const smoothScroll = new SmoothScroll();

  // ソート追従
  const productList = new ProductList();

  // パララックス
  const typeEffect = new TypeEffect();

  // ボタン非活性
  const btnCheck = new BtnCheck();

  // テキストエリア
  // const textarea = new Textarea();

  // 個数選択
  const quantity = new Quantity();

  // カートモーダル
  const modalCart = new ModalCart();

  // 問診票モーダル
  const modalSite = new ModalSite();

  // 消費税計算
  const tax = new Tax();

  // スクロールでボタン表示
  const scrollBtn = new ScrollBtn();
});

// forEach Polyfill
NodeList.prototype.forEach = NodeList.prototype.forEach || function( onExecute, thisArg ) {
  thisArg     = thisArg || window;
  const COUNT = this.length;

  for ( let i = 0; i < COUNT; i++ ) {
      onExecute.call( thisArg, this[i], i, this );
  }
};