export default class pagelink {
  constructor() {
    this.init();
  }
  init() {
    $('#js-pageLink a[href*="#"]').click(function () {
      var elmHash = $(this).attr('href');
      var pos = $(elmHash).offset().top - 69;
      $('body,html').animate({scrollTop: pos}, 650);
      return false;
    });
  }
}