export default class productDtl {
  constructor() {
    this.init();
  }
  init() {
    if($(".l-footer").length) {
      window.addEventListener('scroll', function () {
        let other = $(".l-footer");
        let otherH = other.offset().top;
        let windowH = $(window).height();
        let windowS = $(window).scrollTop();
        
        if (window.matchMedia('(max-width: 767px)').matches) {
          if (windowS < otherH - windowH - 150) {
            $(".js-productScrollInfo").fadeIn();
          } else {
            $(".js-productScrollInfo").fadeOut();
          }
        } else if (window.matchMedia('(min-width:768px)').matches) {
          if (windowS < otherH - windowH) {
            $(".js-productScrollInfo").fadeIn();
          } else {
            $(".js-productScrollInfo").fadeOut();
          }
        }
      });
    }
  }
}