export default class pass {
  constructor() {
    this.init();
  }
  init() {
    let eye = document.getElementById("js-pass");
    if(eye){
      eye.addEventListener('click', function () {
        if (this.previousElementSibling.getAttribute('type') == 'password') {
        this.previousElementSibling.setAttribute('type', 'text');
        this.classList.add('is-opacity');
        } else {
        this.previousElementSibling.setAttribute('type', 'password');
        this.classList.remove('is-opacity');
        }
      })
    }
  }
}