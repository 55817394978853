export default class mainDtl {
  constructor() {
    this.init();
  }
  init() {
    if($(".p-frontProduct").length) {
      window.addEventListener('scroll', function () {

        let other = $(".p-frontProduct");
        let otherH = other.offset().top;
        let windowH = $(window).height();
        let windowS = $(window).scrollTop();

        if (window.matchMedia('(max-width: 767px)').matches) {
          if (windowS < otherH - windowH) {
            $(".js-frontScrollInfo").fadeIn();
          } else {
            $(".js-frontScrollInfo").fadeIn();
          }
        } else if (window.matchMedia('(min-width:768px)').matches) {
          if (windowS < otherH - windowH) {
            $(".js-frontScrollInfo").fadeIn();
          } else {
            $(".js-frontScrollInfo").fadeOut();
          }
        }
      });
    }
  }
}