export default class textcut {
  constructor() {
    this.init();
  }
  init() {
    function textCut(selector, size) {
      var afterTxt = '･･･';
      selector.each(function () {
        var textLength = $(this).text().length;
        if (size < textLength) {
          var textTrim = $(this).text().substr(0, size);
          $(this).html(textTrim + afterTxt);
        }
      });
    }
    
    textCut($('.js-textcut'), '180');
  }
}