export default class load {
  constructor() {
    this.init();
  }
  init() {
    function end_loader() {
      $('.c-loader').fadeOut(500);
    }
    function show_txt() {
      $('.c-loader .c-loader__img').addClass('is-show');
    }
    function hide_txt() {
      $('.c-loader .c-loader__img').fadeOut(500);
    }
    $(window).on('load', function () {
      setTimeout(function () {
        show_txt();
      }, 50)
      setTimeout(function () {
        hide_txt();
      }, 1000)
      setTimeout(function () {
        end_loader();
      }, 1000)
    })
  }
}