export default class scrollBtn {
  constructor() {
    this.init();
  }
  init() {
    let object = document.querySelector('.js-scrollBtn');
    let trigger = document.querySelector('.js-srollTrigger');
    let footer = document.querySelector('.p-footer');
    let windowH = document.documentElement.clientHeight;

    if(trigger) {
      window.addEventListener('scroll', function () {
        let triggerP = trigger.offsetTop;
        let footerP = footer.offsetTop;
        let scroll = window.pageYOffset;

        if (scroll < triggerP - windowH) {
          object.classList.add('is-hide');
        } else {
          object.classList.remove('is-hide');
        }

        if (!(scroll < footerP - windowH - 150)) {
          object.classList.add('is-hide');
        }

        if (window.matchMedia('(max-width: 767px)').matches) {

        } else if (window.matchMedia('(min-width:768px)').matches) {

        }
      });
    }
  }
}