export default class tax {
  constructor() {
    this.init();
  }
  init() {
    let tax = document.querySelectorAll('.js-tax');
    let taxNum = 1.1;

    for (let i = 0; i < tax.length; i++) {
      let box = tax[i];
      let txt = box.querySelector('.js-taxTxt');
      let txtInc = box.querySelector('.js-taxInc');

      let txtNum = txt.textContent;
      let mathTxt = txtNum / taxNum;
      let txtIncNum = txtInc.textContent = mathTxt;
      
      const numberWithComma = new Intl.NumberFormat();
      const commaTxt = numberWithComma.format(txtNum);
      const commaTax = numberWithComma.format(txtIncNum);

      txt.textContent = commaTxt;
      txtInc.textContent = commaTax;
    }
  }
}