export default class btnCheck {
  constructor() {
    this.init();
  }
  init() {
    let form = document.querySelectorAll('.js-radioBox')
    let check = document.querySelector('.js-checkInfo')
    let checkBtn = document.querySelector('.js-checkBtn')
    let submit = document.querySelector('.p-modalForm__btnSubmit')

    if (submit) {
      submit.disabled = true
    }

    // 使用できないとき、テキスト表示
    for (let i = 0; i < form.length; i++) {
      let box = form[i]
      let text = box.querySelector('.js-noUse')
      let yes = box.querySelector('.js-yes')
      let no = box.querySelector('.js-no')

      // 「はい」で文言表示
      text.style.display = 'none'
      yes.addEventListener('click', function () {
        text.style.display = 'block'
        submit.disabled = true
        checkBtn.style.pointerEvents = 'none'
        if (check.checked) {
          check.checked = false
        }
      })
      no.addEventListener('click', function () {
        text.style.display = 'none'
        checkBtn.style.pointerEvents = 'auto'
      })
    }

    // 問診票
    let question = document.querySelectorAll('.js-check')

    for (let i = 0; i < question.length; i++) {
      let btn = question[i]
      let questionBtn = btn.querySelector('.js-checkInfo');
      let check01 = question[0].querySelector('.js-checkInfo');
      let check02 = question[1].querySelector('.js-checkInfo');

      questionBtn.addEventListener('change', function () {
        if (check01.checked == true && check02.checked == true) {
          submit.disabled = false
        } else {
          submit.disabled = true
        }
      })
    }
  }
}