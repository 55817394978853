export default class quantity {
  constructor() {
    this.init();
  }
  init() {
    let noItem = document.querySelector('.p-modalCart__null');
    let adItem = document.querySelector('.p-modalCart__box');
    let list = document.querySelector('.p-modalCart__list');
    let quantityBox = document.querySelectorAll(".p-modalCart__item");

    if(list) {
      if(list.childElementCount == 0) {
        $(adItem).addClass('is-hide');
        $(noItem).addClass('is-show');
        $(adItem).removeClass('is-show');
        $(noItem).removeClass('is-hide');
      } else {
        $(adItem).addClass('is-show');
        $(noItem).addClass('is-hide');
        $(adItem).removeClass('is-hide');
        $(noItem).removeClass('is-show');
      }
    }
    
    for (let i = 0; i < quantityBox.length; i++) {
      let box = quantityBox[i];
      let item = box.querySelector('.c-qty');
      let num = item, count = 1;
      let minBtn = box.querySelector('.c-qtyminus.c-minus');
      let plusBtn = box.querySelector('.c-qtyplus.c-plus');

      num.innerHTML = count;

      if(minBtn){
        minBtn.addEventListener("click", () => {
          if (count > 0) {
              count--;
            } else {
              count = 0;
            }
          num.innerHTML = count;

          if(num.innerHTML == 0) {
            box.remove();
          }

          if(list.childElementCount == 0) {
            $(adItem).addClass('is-hide');
            $(noItem).addClass('is-show');
            $(adItem).removeClass('is-show');
            $(noItem).removeClass('is-hide');
          }
        });
      }

      if(plusBtn){
        plusBtn.addEventListener("click", () => {
          count++;
          num.innerHTML = count;
        });
      }
    }
  }
}
