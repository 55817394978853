export default class gnav {
  constructor() {
    this.init();
  }
  init() {
    let ham = document.querySelector('.js-humBtn');
    let gnav = document.querySelector('.js-gnav');
    let gnavBg = document.querySelector('.js-gnavBg');
    let posAb = document.querySelector('.p-logo--posAb');
    let spNav = document.querySelector('.p-header');
    let state = false;
    let pos;

    if(posAb) {
      ham.addEventListener('click', function () {
        posAb.classList.toggle('is-change');
      });
    }
    
    if(ham){
      ham.addEventListener('click', function () {
        ham.classList.toggle('is-active');
        gnav.classList.toggle('is-open');
        gnavBg.classList.toggle('is-open');
        gnav.classList.toggle('is-trans');
        spNav.classList.toggle('is-hide');

        if (state == false) {
          pos = $(window).scrollTop();
          $('body').addClass('is-fixed').css({'top': -pos});
          state = true;
        } else {
          $('body').removeClass('is-fixed').css({'top': 0});
          window.scrollTo(0, pos);
          state = false;
        }
      });

      $(window).on("scroll", function() {
        if ($(this).scrollTop() > 40) {
          $(".js-headerColor").addClass("is-color");
        } else {
          $(".js-headerColor").removeClass("is-color");
        }
      });
    }
  }
}