export default class productList {
  constructor() {
    this.init();
  }
  init() {
    $(function(){
      let cont = document.querySelector('.js-searchNavi');

      if(cont) {
        var fix    = $(".js-searchNavi"); 
        var fixH    = fix.height(); 
        var fixTop = fix.offset().top;
        var header = $(".p-header");
        var headerH    = header.height(); 

        if (window.matchMedia('(max-width: 767px)').matches) {
          if($(window).scrollTop() >= fixTop - fixH) {
            fix.css("position",""); 
            fix.css("top",""); 
          } else {
            fix.css("position",""); 
            fix.css("top",""); 
          }
        } else if (window.matchMedia('(min-width:768px)').matches) {
          if($(window).scrollTop() >= fixTop - fixH) {
            fix.css("position","fixed");
            fix.css("top",headerH - 4); 
          } else {
            fix.css("position",""); 
            fix.css("top",""); 
          }
        }
  
        $(window).scroll(function () { 
          if (window.matchMedia('(max-width: 767px)').matches) {
            if($(window).scrollTop() >= fixTop - fixH) {
              fix.css("position",""); 
              fix.css("top",""); 
            } else {
              fix.css("position",""); 
              fix.css("top",""); 
            }
          } else if (window.matchMedia('(min-width:768px)').matches) {
            if($(window).scrollTop() >= fixTop - fixH) {
              fix.css("position","fixed");
              fix.css("top",headerH - 4); 
            } else {
              fix.css("position",""); 
              fix.css("top",""); 
            }
          }
        });
  
        window.addEventListener('scroll', function () {
          let other = $(".p-footer");
          let otherH = other.offset().top;
          let windowH = $(window).height();
          let windowS = $(window).scrollTop();
          if (window.matchMedia('(max-width: 767px)').matches) {
            if (windowS < otherH - windowH - 150) {
              $(".js-searchNavi").fadeIn();
            } else {
              $(".js-searchNavi").fadeOut();
            }
          } else if (window.matchMedia('(min-width:768px)').matches) {
            if (windowS < otherH - windowH) {
              $(".js-searchNavi").fadeIn();
            } else {
              $(".js-searchNavi").fadeOut();
            }
          }
        });
      }
    });
  }
}